<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-1/2 pr-1 mb-2">
        <!-- <label for="">Mitarbeiter *</label> -->
        <v-select
          :options="personals"
          v-model="employee.personal"
          :reduce="personal => personal.id"
          :clearable="false"
          placeholder="Mitarbeitende auswählen"
          label="full_name"
          class="style-chooser mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent w-full"
        />
        <!-- <dropdown
          v-model="employee.personal"
          label="Mitarbeiter *"
          placeholder="Mitarbeiter wählen"
          :options="personals"
        /> -->
        <!-- <select
            v-model="employee.personal"
            class="mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.employee.personal.$error }"
          >
            <option value="">Mitarbeiter wählen</option>
            <option
              v-for="(personal, index) in personals"
              :key="`option-${index}`"
              :value="personal.id"
            >
              {{ personal.first_name }} {{ personal.last_name }}
            </option>
          </select> -->
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-1/2 flex flex-col">
          <label for="">Pensum</label>
          <input
            type="text"
            v-model="employee.workload"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{ 'border-red-500': $v.employee.workload.$error }"
            placeholder="Pensum"
          />
        </div>

        <div class="w-1/2 flex flex-col">
          <label for="">Pensum Home Office</label>
          <input
            type="text"
            v-model="employee.workload_home_office"
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
            :class="{
              'border-red-500': $v.employee.workload_home_office.$error,
            }"
            placeholder="Pensum Home Office"
          />
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div class="w-1/2 flex flex-col">
          <label for="">Startdatum</label>
          <date-picker
            v-model="employee.start_date"
            :input-class="
              `border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent ${
                $v.employee.start_date.$error ? 'border-red-500' : ''
              }`
            "
            placeholder="Startdatum"
          />
        </div>

        <div class="w-1/2 flex flex-col">
          <label for="">Enddatum</label>
          <date-picker
            v-model="employee.leaving_date"
            :input-class="
              `border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent`
            "
            placeholder="Enddatum"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { getAll } from '@/services/personal'
import { createOne, updateOne } from '@/services/employee'

const tempEmployee = {
  personal: '',
  workload: '0',
  workload_home_office: '0',
  start_date: '',
  leaving_date: '',
  archived: false,
  roleprofile: null,
}

export default {
  name: 'new-employee',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      employee: {},
      personals: [],
    }
  },
  validations() {
    return {
      employee: {
        personal: {
          required,
        },
        workload: {
          required,
        },
        workload_home_office: {
          required,
        },
        start_date: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.employee = this.payload
        ? { ...this.payload, personal: this.payload.personal.id }
        : { ...tempEmployee, roleprofile: this.parent_id }

      var no_pagination = true
      getAll(no_pagination).then(response => {
        this.personals = response.data.results.map(personal => ({
          ...personal,
          full_name: `${personal.first_name} ${personal.last_name}`,
        }))
      })
    },
    save() {
      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        if (this.employee.id) {
          this.employee['roleprofile'] = this.employee.roleprofile.id

          updateOne(this.employee).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          this.employee['roleprofile'] = this.parent_id

          createOne(this.employee)
            .then(() => {
              this.$emit('close-modal', { show: false })

              // // this.$toast.success(response.data.message)
            })
            .catch(err => {
              console.log('E ::: ', err.response)
            })
        }
      }
    },
  },
  watch: {},
}
</script>
